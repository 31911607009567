<template>
  <a @click="this.$router.go(-1)" class="block flex hover:bg-redDamask focus:bg-redDamask active:bg-redDamask hover:bg-opacity-20 focus:bg-opacity-20 active:bg-opacity-20 border border-transparent rounded-full max-w-max pr-4 cursor-pointer">
    <BaseIcon name="arrowLeft" outline= false />
    <span class="inline-block font-body text-bodyM dark:text-halfColonialWhite text-sepiaBlack my-auto">{{ $t('entity.toListBtn') }}</span>
  </a>
</template>

<script>
import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'BackToList',
  components: {
    BaseIcon
  }
}
</script>
