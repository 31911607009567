<template>
  <div v-if="type == 'error'" class="inline-block relative" id="systemM">
    <BaseIcon name="closeWhite" class="absolute top-2 right-0 select-none" outline= false @click="closeMessage()" />
    <span class="inline-block dark:text-white text-sepiaBlack font-body text-bodyXL bg-error pl-4 pr-10 py-4 rounded-lg border-error border">{{ text }}</span>
  </div>
  <div v-else-if="type == 'warning'" class="inline-block relative" id="systemM">
    <BaseIcon name="closeWhite" class="absolute top-2 right-0 select-none" outline= false @click="closeMessage()" />
    <span class="inline-block dark:text-white text-sepiaBlack font-body text-bodyXL bg-warning pl-4 pr-10 py-4 rounded-lg border-warning border">{{ text }}</span>
  </div>
  <div v-else class="bg-success inline-block relative" id="systemM">
    <BaseIcon name="closeWhite" class="absolute top-2 right-0 select-none" outline= false @click="closeMessage()" />
    <span class="inline-block dark:text-white text-sepiaBlack font-body text-bodyXL bg-success pl-4 pr-10 py-4 rounded-lg border-success border">{{ text }}</span>
  </div>
</template>

<script>

import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'SystemMessage',
  components: {
    BaseIcon
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: 'success'
    }
  },
  methods: {
    closeMessage() {
      let modal = document.querySelector('#systemM')
      modal.classList.add('hidden')
    }
  }
}
</script>
