<template>
  <div v-if="theme == 'dark' ? 'sticy-header-background' : 'white-bg'" id="header" v-bind:class="headerBg + ' flex justify-between items-center px-3 h-20 sticky top-0 z-50'">
    <div class="flex gap-4">
      <div v-if="back == true">
				<BackToList />
      </div>
      <div v-if="backArrow == true" @click="this.$router.go(-1)">
				<BaseIcon name="arrowLeft"/>
      </div>
      <DarkMode class="hidden" id="darkmode" :theme="theme" @modeChanged="modeChanged" @click="changeIcon"/>
      <!-- <FontSize class="hidden" id="fontsize"/> -->
      <BaseIcon :name="iconName" bg=true v-on:click="toggle_Settings"/>
    </div>
    <div v-if="languageVisibility == true">
       <Language :language="language" @langchanged="langchanged" />
    </div>
  </div>
</template>

<script>

import Language from "@/components/Language.vue";
import BaseIcon from '@/components/BaseIcon.vue';
import BackToList from "@/components/tablet/BackToList.vue";
// import FontSize from "@/components/tablet/FontSize.vue";
import DarkMode from '@/components/DarkMode.vue';

export default {
  name: 'Header',
  components: {
    Language,
    BaseIcon,
    BackToList,
    // FontSize,
    DarkMode,
  },
  props: {
    language: {
      type: String,
      default: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    },
    languageVisibility: {
      type: Boolean,
      required: false,
      default: true,
    },
    back: {
      type: Boolean,
      required: false,
      default: true
    },
    backArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    deviceHeader: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      iconName: localStorage.getItem('theme') != 'dark' ? 'settings' : 'settingsWhite',
      theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark',
      headerBg: localStorage.getItem('theme') != 'dark' ? 'white-bg' : 'sticy-header-background'
    }
  },
  methods: {
    langchanged(value) {
      this.$emit('langchanged', value);
    },
    modeChanged(value) {
      this.$emit('modeChanged', value);
    },
    changeIcon() {
      if (document.querySelector("html").classList.contains("dark")) {
        this.iconName = 'closeWhite';
      } else {
        this.iconName = 'close';
      }
    },
    toggle_Settings: function() {
      switch (this.iconName) {
        case 'settingsWhite':
          document.querySelector('#darkmode').classList.remove('hidden');
          this.iconName = 'closeWhite'
          break;
        case 'settings':
          document.querySelector('#darkmode').classList.remove('hidden');
          this.iconName = 'close'
          break;
        case 'closeWhite':
          document.querySelector('#darkmode').classList.add('hidden');
          this.iconName = 'settingsWhite';
          break;
        case 'close':
          document.querySelector('#darkmode').classList.add('hidden');
          this.iconName = 'settings';
          break;
      }
    }
  },
}
</script>
<style>
.sticy-header-background{
  background: linear-gradient(180deg, #261710 0%, rgba(38, 23, 16, 0.67) 60.82%, rgba(38, 23, 16, 0) 100%);
}

.white-bg {
  background: white;
}
</style>