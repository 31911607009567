<template>
<div class="flex gap-2 justify-center">
  <input id="custom_input_1" inputmode="numeric" type="text" maxlength="1" v-model="inputValue1" v-on:keyup="jumpToNext()" @change="pinSave(1)" v-on:click="focusChange(1)" class="firstPinInput px-4 py-5 w-10 text-sepiaBlack dark:text-white font-body text-bodyM rounded-full border border-halfColonialWhite-transparent30 bg-twine-transparent40 focus:outline-none focus:ring-0 focus:border-redDamask">
  <input id="custom_input_2" inputmode="numeric" type="text" maxlength="1" v-model="inputValue2" v-on:keyup="jumpToNext()" @change="pinSave(2)" v-on:click="focusChange(2)" class="px-4 py-5 w-10 text-sepiaBlack dark:text-white font-body text-bodyM rounded-full border border-halfColonialWhite-transparent30 bg-twine-transparent40 focus:outline-none focus:ring-0 focus:border-redDamask">
  <input id="custom_input_3" inputmode="numeric" type="text" maxlength="1" v-model="inputValue3" v-on:keyup="jumpToNext()" @change="pinSave(3)" v-on:click="focusChange(3)" class="px-4 py-5 w-10 text-sepiaBlack dark:text-white font-body text-bodyM rounded-full border border-halfColonialWhite-transparent30 bg-twine-transparent40 focus:outline-none focus:ring-0 focus:border-redDamask">
  <input id="custom_input_4" inputmode="numeric" type="text" maxlength="1" v-model="inputValue4" @change="pinSave(4)" v-on:click="focusChange(4)" class="px-4 py-5 w-10 text-sepiaBlack dark:text-white font-body text-bodyM rounded-full border border-halfColonialWhite-transparent30 bg-twine-transparent40 focus:outline-none focus:ring-0 focus:border-redDamask">
</div>
</template>

<script>
export default {
  name: 'PinCodeInput',
  props: {
    inputValueProp1: {
      type: String,
      default: '',
    },
    inputValueProp2: {
      type: String,
      default: '',
    },
    inputValueProp3: {
      type: String,
      default: '',
    },
    inputValueProp4: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      inputValue1: this.inputValueProp1,
      inputValue2: this.inputValueProp2,
      inputValue3: this.inputValueProp3,
      inputValue4: this.inputValueProp4,      
    }
  },
  methods: {
    pinSave(index) {
      switch(index) {
        case 1:
          var value = this.inputValue1;
          break;
        case 2:
          var value = this.inputValue2;
          break;
        case 3:
          var value = this.inputValue3;
          break;
        case 4:
          var value = this.inputValue4;
          break;
      }
      this.$emit('pinChange', this.createPin.pin, index, value);
    },
    focusChange(value) {
      this.$emit('focusChange', value);
    },
    jumpToNext() {
      let key = event.keyCode || event.charCode;
      let nextElementSibling = window.event.target.nextElementSibling
      if( key !== 8 && key !== 46 ) {
        nextElementSibling.focus()
      }
    },
  },
  computed: {
    createPin() {
      return {
        pin: `${this.inputValue1}${this.inputValue2}${this.inputValue3}${this.inputValue4}`
      }
    }
  },
  watch: {
    inputValueProp1: function(newValue) { // watch it
      this.inputValue1 = newValue;
    },
    inputValueProp2: function(newValue) { // watch it
      this.inputValue2 = newValue;
    },
    inputValueProp3: function(newValue) { // watch it
      this.inputValue3 = newValue;
    },
    inputValueProp4: function(newValue) { // watch it
      this.inputValue4 = newValue;
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
</style>
